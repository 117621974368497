<template>
  <div>
    <div class="social-media-connections-introduction">
      <CCard>
        <CCardHeader>
          <h5>So binden Sie Ihre Social-Media-Konten an</h5>
        </CCardHeader>
        <CCardBody>
          <p>Verbinden Sie Ihre Social-Media-Konten mit unserer Plattform, um Inhalte direkt zu veröffentlichen und die Leistung Ihrer Beiträge zu analysieren. Folgen Sie diesen einfachen Schritten, um zu beginnen:</p>
          <ul>
            <li>Wählen Sie das Netzwerk, das Sie verbinden möchten.</li>
            <li>Folgen Sie den Anweisungen, um sich bei Ihrem Konto anzumelden und die Berechtigungen zu erteilen.</li>
            <li>Nach erfolgreicher Verbindung können Sie Beiträge planen und veröffentlichen.</li>
          </ul>
          <p>Für detailliertere Anleitungen und Tipps, besuchen Sie unsere <router-link to="/socialMedia/hilfe/">Hilfeseite</router-link>.</p>
        </CCardBody>
      </CCard>
    </div>

    <div class="social-media-connections-row">
      <CRow class="social-media-connections-row">
        <CCol lg="3" class="social-media-connection-card">
          <CCard :class="getCardClasses('FACEBOOK')">
            <CCardHeader>
              <CIcon name="cib-facebook" size="xl"/>
              <h3>Facebook</h3>
            </CCardHeader>
            <CCardBody>
              <p v-if="isConnected('FACEBOOK')">
                Sie sind aktuell mit dem Facebook-Account <strong>{{ connections.FACEBOOK.name }}</strong> angemeldet.
              </p>
              <p v-else>
                Sie sind aktuell noch nicht mit einem Facebook-Account angemeldet.
                Melden Sie sich an, um Beiträge über die Marketing Cloud zu posten.
              </p>
            </CCardBody>
            <CCardFooter>
              <CButton
                color="primary"
                :class="{ 'btn-danger': isConnected('FACEBOOK'), 'btn-secondary': !isConnected('FACEBOOK') }"
                @click="onClickFacebook"
              >
                {{ isConnected('FACEBOOK') ? 'Verknüpfung aufheben' : 'Mit Facebook verbinden' }}
              </CButton>
            </CCardFooter>
          </CCard>
        </CCol>
        <CCol lg="3" class="social-media-connection-card">
          <CCard :class="['social-media-connection', 'instagram', connections.hasOwnProperty('INSTAGRAM') ? '' : 'not-connected']">
            <CCardHeader>
              <slot name="header">
                <CIcon name="cib-instagram" size="xl"/>
                <h3>Instagram</h3>
              </slot>
            </CCardHeader>
            <CCardBody>
              <p>Sie sind aktuell noch <strong>nicht</strong> mit einem Instagram-Account angemeldet.</p>
              <small>Melden Sie sich an um Beiträge über die Marketing Cloud zu posten.</small>
            </CCardBody>
            <CCardFooter>
              <ProcessingButton
                @click=""
                :disabled="true"
                :processing="processingInstagram"
                idle-color="secondary"
                processing-color="primary"
                idle-text="Bald verfügbar"
                processing-text="Wird verbunden..."
              />
            </CCardFooter>
          </CCard>
        </CCol>
        <CCol lg="3" class="social-media-connection-card">
          <CCard :class="['social-media-connection', 'linkedin', connections.hasOwnProperty('LINKEDIN') ? '' : 'not-connected']">
            <CCardHeader>
              <slot name="header">
                <CIcon name="cib-linkedin" size="xl"/>
                <h3>LinkedIn</h3>
              </slot>
            </CCardHeader>
            <CCardBody>
              <p>Sie sind aktuell noch <strong>nicht</strong> mit einem LinkedIn-Account angemeldet.</p>
              <small>Melden Sie sich an um Beiträge über die Marketing Cloud zu posten.</small>
            </CCardBody>
            <CCardFooter>
              <ProcessingButton
                @click=""
                :disabled="true"
                :processing="processingInstagram"
                idle-color="secondary"
                processing-color="primary"
                idle-text="Bald verfügbar"
                processing-text="Wird verbunden..."
              />
            </CCardFooter>
          </CCard>
        </CCol>
      </CRow>
      <FacebookModal @sdk-init="onSdkInit" :show-modal.sync="showFacebookModal" />
    </div>
  </div>
</template>

<script>
import DoubleBounceLoader from '@/components/loaders/DoubleBounceLoader'
import commons from '@/commons'
import axios from 'axios'
import ProcessingButton from "@/components/ProcessingButton.vue";
import Checkbox from "@/components/Checkbox.vue";
import ExtraStepsAddStepList from "@/components/ExtraStepsAddStepList.vue";
import FacebookModal from "@/views/base/social-media/facebook/FacebookModal.vue";

export default {
  name: 'SocialMediaConnections',
  components: {
    FacebookModal,
    ExtraStepsAddStepList,
    Checkbox,
    ProcessingButton,
    DoubleBounceLoader
  },
  data() {
    return {
      loading: false,
      saving: false,
      connections: {
        FACEBOOK: {}
      },
      facebookProfilePicUrl: '',
      tableShowLoading: true,
      processingInstagram: false,
      showFacebookModal: false,
      fbSdk: null
    }
  },
  created() {

  },
  mounted() {
    this.loading = true
    this.loadSocialMediaConnections()
  },
  methods: {
    loadSocialMediaConnections() {
      axios.get('/socialMedia/connections')
        .then(response => {
          let content = {}
          for (const [key, value] of Object.entries(response.data)) {
            content[key] = commons.flatObject(value)
          }
          this.connections = content
          this.loading = false
          console.log(content)
          //this.getFBData(content.FACEBOOK.accessToken)
        })
        .catch(err => console.error('Problem while fetching social media connections', err))
        .finally(() => this.tableShowLoading = false)
    },
    getTableFields() {
      return [
        { key: 'name', label: 'Name' },
        { key: 'domain', label: 'Domain' },
        { key: 'active', label: 'Aktiv?' },
        {
          key: 'show_details',
          label: '',
          _style: 'width:1%',
          sorter: false,
          filter: false
        }
      ]
    },
    getTableBadge(type) {
      return type ? 'success' : 'danger'
    },
    isConnected(platform) {
      return this.connections.hasOwnProperty(platform);
    },
    getCardClasses(platform) {
      return [
        'social-media-connection',
        platform.toLowerCase(),
        this.isConnected(platform) ? '' : 'not-connected',
      ];
    },
    onClickFacebook() {
      if (this.connections.hasOwnProperty('FACEBOOK')) {
        this.$modal.showModal(
          'delete',
          null,
          [`<b>Facebook-Verbindung</b> (${this.connections.FACEBOOK.name})`, `Alle zukünftigen Social-Media Beiträge für Facebook können nicht mehr gepostet werden.`],
          async () => {
            axios.delete('/socialMedia/connections/' + this.connections.FACEBOOK.id)
              .then(() => {
                this.$router.go()
                this.$toaster.makeToast('Erfolgreich!', '<b>Facebook wurde erfolgreich entkoppelt!</b>')
              })
              .catch(err => {
                if (err.response) {
                  this.$toaster.makeToast('Oops!', '<b>' + err.response.data.code + '</b><br>' + err.response.data.message)
                } else {
                  this.$toaster.makeToast('Error!', err.message)
                }
              })
          }
        )
      } else {
        this.showFacebookModal = true
      }
    },
    onSdkInit(fbSdk) {
      this.fbSdk = fbSdk
    }
  }
}
</script>

<style lang="scss">
.social-media-connection {
  .card-header {
    display: flex;
    align-items: center;
    justify-content: center;
    h3 {
      font-size: 1.25rem;
      margin-left: 0.5rem;
    }
    .c-icon {
      font-size: 2rem;
    }
  }
  &.not-connected .card-header {
    background-color: #d3d3d3;
  }

  .card-footer .btn {
    transition: transform 0.1s ease-in-out;
    &:hover {
      transform: translateY(-2px);
    }
  }
  .social-media-connections-row {
    display: flex;
    flex-wrap: wrap;
  }
  .social-media-connection-card {
    display: flex;
    flex-direction: column;
  }

  .card-body {
    flex: 1;
    min-height: 150px;
  }

  .card-footer {
    .btn {
      &.facebook {
        background-color: #4267B2;
        &:hover {
          background-color: darken(#4267B2, 10%);
        }
      }
      &.instagram {
        background-color: #E1306C;
        &:hover {
          background-color: darken(#E1306C, 10%);
        }
      }
      &.linkedin {
        background-color: #2867B2;
        &:hover {
          background-color: darken(#2867B2, 10%);
        }
      }
      &.disabled {
        background-color: #cccccc;
        &:hover {
          background-color: darken(#cccccc, 10%);
        }
      }
    }
  }
}
</style>

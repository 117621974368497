<template>
  <CModal
      title="Facebook mit Leadmeup verbinden"
      :show="showModal"
      @update:show="$emit('update:showModal', $event)"
      style="text-align: left"
  >
    <strong>Schritt 1: Einloggen</strong>
    <!--<div class="fb-login-button" data-width="" data-size="large" data-button-type="login_with" data-layout="default" data-auto-logout-link="false" data-use-continue-as="true"></div>-->
    <v-facebook-login
        style="margin-top:4px;cursor:pointer;"
        :app-id="facebookAppId"
        version="v18.0"
        sdk-locale="en_US"
        :login-options="loginOptions"
        @sdk-init="onSdkInit"
        @login="onLogin"
        @logout="onLogout"
    >
      <template #login>
        Mit Facebook verbinden
      </template>
    </v-facebook-login>

    <br>
    <strong>Schritt 2: Seite auswählen</strong>
    <p>Hier müssen Sie eine Facebook-Business Seite auswählen</p>

    <CSelect
        v-if="userIsLoggedIn"
        label=""
        :value="selectedFacebookPageId"
        @update:value="onPageSelect($event)"
        :disabled="!userIsLoggedIn || pageOptions === null"
        :options="pageOptions || [{label: '-- Bitte erst einloggen --', value: 0}]"
        description="Sie benötigen Admin-Rechte, damit Sie Ihre Facebook-Seite mit unserem LeadMeUp verbinden können"
    />
    <template v-slot:footer>
      <button
          type="button"
          class="btn btn-secondary"
          @click="cancelModal"
      >
        Abbrechen
      </button>
      <button
          v-if="showDisconnectButton"
          type="button"
          class="btn btn-danger"
          @click="deleteExtraStep"
      >
        Entkoppeln
      </button>
      <button
          type="button"
          class="btn btn-success"
          @click="confirmModal"
      >
        Mit Facebook verbinden!
      </button>
    </template>
  </CModal>
</template>

<script>
import VFacebookLogin from 'vue-facebook-login-component'
import commons from "@/commons";
import _ from 'lodash'
import {cache} from "@vue/cli-service/lib/config/terserOptions";
import axios from "axios";
export default {
  name: 'FacebookModal',
  components: {
    VFacebookLogin
  },
  props: {
    showModal: Boolean
  },
  data() {
    return {
      loginOptions: {
        scope: 'pages_show_list,pages_read_engagement,pages_manage_posts,public_profile,email',
        config_id: process.env.VUE_APP_FACEBOOK_CONFIG_ID,
        // config_id: '1461002427823894',
        //response_type: 'code', // only when using Systemnutzer-Zugriffsschlüssel
        //override_default_response_type: true // only when using Systemnutzer-Zugriffsschlüssel,
      },
      fbSdk: null,
      facebookAppId: process.env.VUE_APP_FACEBOOK_APP_ID,
      showDisconnectButton: false,
      userIsLoggedIn: false,
      facebookAccounts: null, // by id
      pageOptions: null,
      selectedFacebookPageId: null
    }
  },
  created() {

  },
  methods: {
    cancelModal() {

    },
    confirmModal() {
      const found = _.find(this.pageOptions, { value: this.selectedFacebookPageId })
      console.log('connect with id', this.selectedFacebookPageId, 'found', found)

      axios.post('/socialMedia/connect', { socialMedia: "FACEBOOK", accessToken: found.access_token, name: found.label, pageId: found.value })
          .then(() => {
            // this.$router.push({name: 'SocialMediaConnections', params: { saved: '1' }})
            this.$router.go()
            this.$toaster.makeToast('Erfolgreich!', '<b>Facebook wurde erfolgreich verbunden!</b>')
            this.$emit('update:showModal', false)
          })
          .catch(err => {
            if (err.response) {
              this.$toaster.makeToast('Oops!', '<b>' + err.response.data.code + '</b><br>' + err.response.data.message)
            } else {
              this.$toaster.makeToast('Error!', err.message)
            }
          })
    },
    onSdkInit(sdk) {
      this.fbSdk = sdk
      console.log('sdkInit', sdk)
      this.$emit('sdk-init', sdk)
      sdk.FB.getLoginStatus((response) => {
        console.log('fb login status:', response)
        if (response.status === 'connected') {
          // the user is logged in and has authenticated your
          // app, and response.authResponse supplies
          // the user's ID, a valid access token, a signed
          // request, and the time the access token
          // and signed request each expire
          // var uid = response.authResponse.userID;
          // var accessToken = response.authResponse.accessToken;
          this.userIsLoggedIn = true
          console.log('user logged in', response)
        } else if (response.status === 'not_authorized') {
          console.log('user logged in, but has not authenticated your app')
        } else {
          console.log('user not logged in')
        }
      });
    },
    onLogin() {
      this.userIsLoggedIn = true
      const FB = this.fbSdk.FB;
      const prom = new Promise((resolve, reject) => {
        let cachedAccounts = this.getCachedPageOptions()
        if (cachedAccounts === null) {
          var access_token =   FB.getAuthResponse()['accessToken'];
          console.log('Access Token = '+ access_token);
          FB.api('/me', function(response) {
            console.log('Good to see you, ' + response.name + '.');
          });
          FB.api('/me/accounts', { fields: 'access_token,name,id' }, response => {
            console.log('Accounts:', response)
            cachedAccounts = response.data.map(p => {
              return { label: p.name, value: p.id, access_token: p.access_token }
            })
            this.setCachedPageOptions(cachedAccounts)
            resolve(cachedAccounts)
          })
        } else {
          console.log('facebook pageOptions loaded from cache:', cachedAccounts)
          resolve(cachedAccounts)
        }
      })
      prom.then(cachedAccounts => {
        this.pageOptions = cachedAccounts
        console.log('pageOptions', this.pageOptions)
        if (cachedAccounts && cachedAccounts.length) this.selectedFacebookPageId = cachedAccounts[0].value
        console.log('onLogin', this.userIsLoggedIn, 'selectedFacebookPageId', this.selectedFacebookPageId)
      })
    },
    onLogout() {
      this.userIsLoggedIn = false
      this.pageOptions = null
      this.removeCachedPageOptions()
      this.$emit('logout')
      console.log('onLogout', this.userIsLoggedIn)
    },
    onPageSelect(page) {
      this.selectedFacebookPageId = page
      console.log("onPageSelect", this.selectedFacebookPageId)
    },
    getCachedPageOptions() {
      const pageOptions = commons.getFromStorage("social_connections_facebook_pageOptions", null)
      if (pageOptions !== null) {
        const timeWhenSet = commons.getFromStorage("social_connections_facebook_pageOptions__time")
        const maxDiffMillis = 1000 * 60 * 90 // 90 min
        const expireAt = timeWhenSet + maxDiffMillis
        const expired = expireAt < Date.now()
        console.log('cache expired?', expired, timeWhenSet)
        return expired ? null : pageOptions
      }
      return null
    },
    setCachedPageOptions(pageOptions) {
      commons.setToStorage("social_connections_facebook_pageOptions", pageOptions)
      commons.setToStorage("social_connections_facebook_pageOptions__time", Date.now())
    },
    removeCachedPageOptions() {
      commons.removeFromStorage("social_connections_facebook_pageOptions")
      commons.removeFromStorage("social_connections_facebook_pageOptions__time")
    }
  }
}
</script>

<style scoped>

</style>
